import React, {useContext, useEffect, useState} from "react"
import './contactos.css'
import {
  Container,
  Row,
  Col
} from 'react-bootstrap'
import axios from "axios"
import parse from 'html-react-parser';
import {isEmpty} from 'lodash'
//contexto
import {CtxContext} from '../../contexto/ctx'
//React-Markdown & Renderers
import ReactMarkdown from 'react-markdown'
import {renderers} from '../../util/reactMarkdownRenderers'
//imgs
import WhatsUpIcon from '../../imagens/whatsupIcon.png'

export default function Contactos() {
  const [isLoading, setIsLoading] = useState(false)
  const [captchaData, setCaptchaData] = useState("")
  const [data, setData] = useState({})
  const ctx = useContext(CtxContext)

  useEffect(() => {
    axios.get(`${ctx.api_url}/contactos`)
    .then(response => setData(response.data))
  }, [ctx.api_url])

  function handleSubmit(event) {
      event.preventDefault();
      setIsLoading(true);
      const nome = document.getElementById('c-nome').value
      const email = document.getElementById('c-email').value
      const mensagem = document.getElementById('c-mensagem').value
      const captcha = document.getElementById('c-captcha').value

      axios.post('/cform', {
          nome: nome,
          email: email,
          mensagem: mensagem,
          captcha: captcha
      }).then(response => {
          setIsLoading(false);
          document.getElementById("contactos-form").reset();
          alert('A mensagem foi enviada ✔')
          window.location.reload() 
      }, rejection => {
          setIsLoading(false);
          let validation_messages = '';
          console.log(rejection.response);
          rejection.response.data.errors.forEach(element => {
            validation_messages += `${element.msg} \n`;
          })
          alert(validation_messages);
      }).catch(errors => {
          setIsLoading(false)
          document.getElementById("contactos-form").reset();
          alert(errors);
      })
  }

    //Procura por caracteres ilegais.
    function checkText(event){
      event.preventDefault();
      let element = event.target;
      let pattern = "[(){}&<>$=]+";
      if(!element.value.match(pattern)){
          element.setCustomValidity("");
      } else {
          element.setCustomValidity('Detectados caracteres ilegais.');
      }
  }

  useEffect(() => {
    axios.get('/captcha')
        .then(response => {
            setCaptchaData(response.data)
            //console.log(response)
        })
  }, [])

  return (
    <Container fluid className="contactos">
      <Row className="justify-content-center" >
        <Col >
            <div style={{
              textAlign: "center",
              marginTop: "20px"
            }}>
              <h1 >
                CONTACTOS
              </h1>
              <h4>
                  {data.Mensagem}
              </h4>
            </div>    
        </Col>
      </Row>
      <Row className="justify-content-center" >
        <Col md={6} className="contactos-info">
            <div className="contactos-info-horarios">
              {/* Outra info relevante */}
              <ReactMarkdown renderers={renderers(ctx.api_url)} source={data.OutraInfoDeContacto} />
              {!isEmpty(data.Morada) && (
                <div className="contactos-morada">
                  <h2>Morada</h2>
                  <p>{data.Morada}</p>
                </div>
              ) }
            </div>    
        </Col>
        <Col md={6} className="contactos-info">
          <div className="contactos-info-contactos">
            <h2>Contactos:</h2>
            <p><i className="fas fa-at"></i> {!isEmpty(data.Email) && data.Email }</p>
            {!isEmpty(data.Contactos_Telefonicos) && data.Contactos_Telefonicos.map(num => (
              <p key={num.id}><img src={WhatsUpIcon} alt="whatsup icon" width="20px" style={{marginBottom: "4px"}}/> {num.NumContacto} </p>
            )) }
          </div>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col className="text-center mt-1">
              <h3>Enviar mensagem</h3>
        </Col>
      </Row>
      <Row className="justify-content-center" >
        <Col>
          {isLoading ? <div className="loader"></div> 
          :
          <div className="contact-form-area">
              <div className="contact-form">
                  <form id="contactos-form" onSubmit={handleSubmit}>
                      <input 
                        id="c-nome" 
                        type="text" 
                        placeholder="Como se chama?" 
                        required 
                        minLength="2" 
                        maxLength="25"
                        onInput={checkText}
                      />
                      <input 
                        id="c-email" 
                        type="email" 
                        placeholder="O seu email" 
                        required
                        minLength="6"
                        maxLength="75"
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,6}$"
                      />
                      <textarea 
                        id="c-mensagem" 
                        placeholder="A sua mensagem"
                        onInput={checkText}
                        required
                        minLength="12"
                        maxLength="2500"
                      ></textarea>
                      <div>
                        {!isEmpty(captchaData) && parse(captchaData)}
                        <input 
                          id="c-captcha" 
                          type="text" 
                          placeholder="Digite os caracteres presentes na imagem." 
                          required 
                          minLength="5" 
                          maxLength="8"
                          onInput={checkText}
                        />
                      </div>
                      <small><a href="https://pt.wikipedia.org/wiki/CAPTCHA" target="_blank" rel="noreferrer">O que é a verificação humana</a></small>
                      <div className="send-btn">
                          <input
                            id="formsend" 
                            type="submit"
                            value="Enviar"
                          />
                      </div>
                  </form>
              </div>
          </div>
          }
        </Col>
      </Row>

    </Container>
  )
}
