import React from 'react'
import './pagesHeader.css'
import {
    Container,
    Row,
    Col
} from 'react-bootstrap'
import {Link} from 'react-router-dom'
//Imgs
import {LazyLoadImage} from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css';
import LogoLight from '../../imagens/CGSNEWLogo.png'
//Componentes
/* import DropDownMenu from '../DropDownMenu/dropDownMenu' */

export default function PagesHeader() {
    return (
        <Container fluid style={{
            backgroundColor: "#BD9235"
        }}>
            <Row>
                <Col style={{textAlign:"center"}}>
                     <LazyLoadImage 
                        effect="blur" 
                        src={LogoLight} 
                        width="400px" 
                        alt="Cgs logo"/>   
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: "center"}}>
                    <div>
                        <ul id="pagesheader-ul">
                            <li className="pagesheader-li"><Link to='/'>HOME</Link> </li>
                            <li className="pagesheader-li"><Link to='/sobre'>SOBRE</Link></li>
                            {/* <DropDownMenu tema="pheader"/> */}
                            <li className="pagesheader-li"><Link to='/servicos-categorias'>SERVICOS</Link></li>
                            <li className="pagesheader-li"><Link to='/blog'>BLOG</Link></li>
                            <li className="pagesheader-li"><Link to='/contactos'>CONTACTOS</Link></li>
                        </ul>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}
