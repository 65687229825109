import React, { useContext } from 'react'
import {
    Container,
    Row,
    Col,
    Carousel
} from 'react-bootstrap'
//css 
import './testemunhos.css'
//Imgs
import {LazyLoadImage} from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css';
//Contexto
import { CtxContext } from '../../contexto/ctx'


export default function Testemunhos() {
    const ctx = useContext(CtxContext)

    return (
        <Container fluid className="testemunhos">
            <Row>
                <Col className="testemunhos-titulo">
                    <h1>Testemunhos</h1>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Carousel>
                        {ctx.pageData.Testemunhos.Slider.map(item => (
                            <Carousel.Item key={item.id}>
                                <div  className="testemunhos-carousel-content">
                                    <LazyLoadImage 
                                        src={ctx.api_url+item.Imagem_cliente.formats.thumbnail.url} 
                                        width="150px" 
                                        effect="blur"
                                        alt={item.Imagem_cliente.alternativeText || "Imagem de testemunho" }
                                    />
                                    
                                    <p className="testemunhos-carousel-item"> 
                                        {item.citacao}
                                    </p>
                                    <h5 className="testemunhos-carousel-item"> {item.Nome_cliente} </h5>
                                </div>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </Col>
            </Row>
        </Container>
    )
} 

