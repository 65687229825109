import React, { useContext } from 'react'
import './info.css'
import {
    Container,
    Row,
    Col,
    Carousel
} from 'react-bootstrap'
//Contexto
import { CtxContext } from '../../contexto/ctx'
//React-Markdown & Renderers
import ReactMarkdown from 'react-markdown'
import {renderers} from '../../util/reactMarkdownRenderers'

export default function Info() {
    const {pageData, api_url} = useContext(CtxContext)
    
    
    return (
        
        <Container fluid>
            <Row style={{
                padding: '35px 0px 35px 0px'
            }}>
                <Col md={4} style={{
                    backgroundColor: '#5ce1e6',
                    padding: '15px',
                    textAlign: 'center'    
                }}>
                    <Carousel>
                    {pageData.Teaser1.Slider.map(item => (
                        <Carousel.Item key={item.id}>
                            <div className='info-carousel-content'>
                                <h2>{item.Titulo}</h2>
                                <h5>{item.Subtitulo}</h5>
                                <div className='info-carousel-message'> 
                                <ReactMarkdown renderers={renderers(api_url)} source={item.Texto} />                                
                                </div>
                            </div>
                        </Carousel.Item>
                    ))}
                    </Carousel>
                </Col>

                <Col md={8} className="info-imagem" style={{
                    backgroundImage: 'url(' + api_url + pageData.Teaser1.Imagem.url + ')',
                    backgroundColor: '#ccc',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat'
                }}> 
                </Col>
            </Row>
        </Container>
    )
}