import React, {useContext, useEffect, useState} from 'react'
import axios from 'axios'
import _ from 'lodash'
// Contexto
import { CtxContext } from '../../contexto/ctx'
// Css
import './promos.css'
//React-Markdown & Renderers
import ReactMarkdown from 'react-markdown'
import {renderers} from '../../util/reactMarkdownRenderers'
//Imgs
import {LazyLoadImage} from 'react-lazy-load-image-component'
import Logo from '../../imagens/CGSNEWLogo.png'
import LinkIcon from '../../imagens/linkIcon.png'
import Separador from '../../imagens/separador.png'


export default function Promos() {
    const ctx = useContext(CtxContext)
    const [data, setData] = useState([])

    useEffect(() => {
       axios.get(`${ctx.api_url}/landing-page`)
        .then(response => {
          setData(response.data)
        })
      }, [ctx.api_url])

    return (
        <>
        {!_.isEmpty(data) &&
        <div className='container-fluid promos'>
            <div className='row align-items-center'>
                <div className='col-sm-12'>
                    <div className='promos-logo'>
                        <LazyLoadImage src={Logo} alt="cgs logo" width="500" />
                    </div>
                </div>
                <div className='col-sm-12' >
                    <h1 className='promos-main-title'>{data.Titulo}</h1>
                </div>
            </div>
            <div className='row align-items-center'>
                <div className='col-sm-12'>
                    <h2 className='promos-subtitle border-top border-bottom'>
                        {data.SubTitulo}
                        <a href="https://carlagabrielsantos.com">
                            <LazyLoadImage src={LinkIcon} alt="cgs logo" width="100" />
                        </a>
                    </h2>
                </div>
            </div>
            
            <div className='row align-items-center '>
                <div className='col-sm-12 d-flex justify-content-center'>
                    <ReactMarkdown className='promos-conteudo' renderers={renderers(ctx.api_url)} source={data.Conteudo} />
                </div>
            </div>

            <div className='row align-items-center pb-3'>
                    <div className='col-sm-12 d-flex justify-content-center'>
                        <div className="footer-info-legal">
                            © {new Date().getFullYear()} Todos os direitos reservados a CarlaGabrielSantos.pt {" "}
                            <a href="/politicas-de-privacidade">Politicas de privacidade.</a> 
                        </div>    
                    </div>
                </div>
            </div>
        }
        </>
    )
}