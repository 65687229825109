import React, { useEffect, useContext, useState } from 'react'
import axios from 'axios'
import _ from 'lodash'
import {
    Container,
    Row,
    Col
} from 'react-bootstrap'
//css
import './politicasPrivacidade.css'
//Contexto
import { CtxContext } from '../../contexto/ctx'
//React-Markdown & Renderers
import ReactMarkdown from 'react-markdown'
import {renderers} from '../../util/reactMarkdownRenderers'

export default function Rd() {
    const [data, setData] = useState({})
    const ctx = useContext(CtxContext)

    useEffect(() => {
        axios.get(`${ctx.api_url}/politicas-privacidade`)
        .then(response => {
          setData(response.data)
        })
    }, [ctx.api_url])

    return (
        <> {!_.isEmpty(data) &&
            <Container className="politicas-privacidade">
                <Row>
                    <Col>
                        <ReactMarkdown renderers={renderers(ctx.api_url)} source={data.Texto} />
                    </Col>
                </Row>
            </Container>
        }
        </>
        
    )
}