import React, { useContext, useState, useEffect } from 'react'
import axios from 'axios'
import _ from 'lodash'
import {
    Container,
    Row,
    Col
} from 'react-bootstrap'
//css
import './footer.css'
//Imgs
import {LazyLoadImage} from 'react-lazy-load-image-component'
import LogoLight from '../../imagens/CGSNEWLogo.png'

//Contexto
import { CtxContext } from '../../contexto/ctx'

export default function Footer() { 
    const [data, setData] = useState({})
    const ctx = useContext(CtxContext)

    useEffect(() => {
        axios.get(`${ctx.api_url}/redes-sociais`)
        .then(response => {
          setData(response.data)
        })
    }, [ctx.api_url])

    return (
        <>{!_.isEmpty(data) && (
            <Container fluid id="footer">
                <Row>
                    <Col>
                        <LazyLoadImage 
                            src={LogoLight}
                            width="180px"
                            alt="footer logo"
                        />    
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ul className="footer-social">
                            {data.Footer.map(item => (
                                <li key={item.id}>
                                    <a href={item.Url}
                                    target="_blank"
                                    rel="noreferrer" 
                                    aria-label="link"
                                    >
                                        <LazyLoadImage 
                                            src={ctx.api_url+item.Imagem.formats.thumbnail.url} 
                                            width="35px" 
                                            effect="blur"
                                            alt={item.alternativeText || "footer facebook icon"} />    
                                    </a>
                                    
                                </li>
                            ))}    
                        </ul>
                    </Col>
                </Row>
                
                <Row>
                    <Col>
                        <div className="footer-info-legal">
                            © {new Date().getFullYear()} Todos os direitos reservados a Carla Gabriel Santos. {" "}
                            <a href="/politicas-de-privacidade">Politicas de privacidade.</a> 
                        </div>    
                    </Col>
                
                </Row>
            </Container>
        )}
        </>
    )
}