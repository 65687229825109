/**React-Markdown renderers são responsaveis por transformar markdown costumizado. */

import React from 'react'
import {Button} from 'react-bootstrap'
//Imgs
import {LazyLoadImage} from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
//Components
import Testemunho from '../Componentes/Testemunho/testemunho'

export const renderers = (api_url) => {
    const mediaMatch = window.matchMedia('(min-width: 500px)').matches
    
    return {
    
        image: ({src, alt}) => {
            return (<>
                {mediaMatch ? (<LazyLoadImage
                    src={api_url + src} 
                    alt={alt} 
                    effect="blur"
                    style={{width: "50%"}} 
                />):(
                    <LazyLoadImage
                    src={api_url + src} 
                    alt={alt} 
                    effect="blur"
                    style={{width: "75%"}} 
                />
                )}
            </>)
        },
        code: ({language, value}) => {

            if(language === "ytvideo") {
                return (
                    <div className="youtube-video">
                        <iframe className="youtube-video-iframe"
                            title="title"
                            src={value} 
                            frameBorder="0" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                            allowFullScreen
                        ></iframe>
                    </div>
                )
            }
            if(language === "canva") {
                return (
                    <div className="canva">
                        <iframe className="canva-iframe"
                            title="title"
                            src={value} 
                            frameBorder="0" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                            allowFullScreen
                        ></iframe>
                    </div>
                )
            }
            if(language === "btnConsulta") {
                return (
                    <>
                        <Button 
                            variant="info"
                            href="/contactos"
                            style={{
                                //backgroundColor: "#5ce1e6", 
                                marginTop: "2px",
                                WebkitBoxShadow: "0px 4px 5px 0px rgba(0,0,0,0.38)",
                                MozBoxShadow: "0px 4px 5px 0px rgba(0,0,0,0.38)",
                                boxShadow: "0px 4px 5px 0px rgba(0,0,0,0.38)",
                            }}
                        >
                            <i className="fas fa-check-square"></i> {value}
                        </Button>
                    </>
                )
            }
            if(language === "testemunho"){
                return (
                    <Testemunho msg={value} />
                )       
            }

        }        
    }
}