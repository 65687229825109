import React from 'react'
import {
  BrowserRouter, Route, Switch
} from 'react-router-dom'
//Contexto
import {CtxProvider} from './contexto/ctx'
//Util
import {AppRoute} from './util/utils'
//Componentes
import PagesLayout from './Layouts/pagesLayout'
import NotFound404 from './Paginas/404/404'
import Promos from './Paginas/Promos/promos'
import ArtigoTemplate from './Templates/ArtigoTemplate/artigoTemplate'
import ServicoTemplate from './Templates/ServicoTemplate/servicoTemplate'
//Paginas
import Home from './Paginas/Home/home'
import PoliticasPrivacidade from './Paginas/PoliticasPrivacidade/politicasPrivacidade'
import Sobre from './Paginas/Sobre/sobre'
import Contactos from './Paginas/Contactos/contactos'
import Blog from './Paginas/Blog/blog'
import ServicosCategorias from './Paginas/ServicosCategorias/servicosCategorias'
import Servicos from './Paginas/Servicos/servicos'

export default function App() {
  return (
    <CtxProvider>
      <BrowserRouter>
        <Switch>

          <AppRoute exact path="/sobre" layout={PagesLayout} component={Sobre} />
          
          <AppRoute exact path="/contactos" layout={PagesLayout} component={Contactos} />
          <AppRoute exact path="/blog" layout={PagesLayout} component={Blog} />
          <AppRoute exact path="/servicos-categorias" layout={PagesLayout} component={ServicosCategorias} />
          <AppRoute exact path="/servicos-categorias/:slug" layout={PagesLayout} component={Servicos} />
          <AppRoute exact path="/artigos/:slug" layout={PagesLayout} component={ArtigoTemplate} />
          <AppRoute exact path="/servicos-categorias/servicos/:slug" layout={PagesLayout} component={ServicoTemplate} />
          <AppRoute exact path="/politicas-de-privacidade" layout={PagesLayout} component={PoliticasPrivacidade} />

          <Route path="/" exact component={Home} />
          <Route exact path="/promos" component={Promos} />
          <Route path="*" exact component={NotFound404} />
        </Switch>
        
      </BrowserRouter>
    </CtxProvider>
  )
}

