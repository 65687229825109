import React from 'react'
import { Fragment } from 'react'



//Componentes
import Footer from '../Componentes/Footer/footer'
import NavTop from '../Componentes/NavTop/navtop'


export default function DefaultLayout({children}) {

    return (
        <Fragment>
            <NavTop />
                {children}
            <Footer />
        </Fragment>
    )
}
