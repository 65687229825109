import React, { useContext, useEffect, useState } from "react"
import {
  Container,
  Row,
  Col
} from 'react-bootstrap'
import axios from 'axios'
import {Link} from 'react-router-dom'
//Imgs
import { LazyLoadImage } from "react-lazy-load-image-component"
import 'react-lazy-load-image-component/src/effects/blur.css'
//Utils
import {slugToUrl} from '../../util/utils'
//contexto
import {CtxContext} from '../../contexto/ctx'
//Paginação
import Paginacao from '../../Componentes/Paginacao/paginacao'
//CSS 
import "./blog.css"


export default function Blog() {
  const ctx = useContext(CtxContext)
  const [data, setData] = useState([])
  const [paginacaoActual, setPaginacaoActual] = useState(1)
  const [artigosPorPagina] = useState(6) //definir aqui o limite 

  useEffect(() => {
    axios.get(`${ctx.api_url}/artigos`)
    .then(response => {
      setData(response.data)
    })
  }, [ctx.api_url])

  //Paginacao 
  const indexUltimoArtigo = paginacaoActual * artigosPorPagina
  const indexDoPrimeiroArtigo = indexUltimoArtigo - artigosPorPagina
  const artigosActuais = data.slice(indexDoPrimeiroArtigo, indexUltimoArtigo)
  const alternaPaginacao = (num) => setPaginacaoActual(num)

  return (
      <Container className="blog" >
        <Row style={{
          textAlign:"center",
          margin: "25px 0px"
          }}
        >
          {
            artigosActuais.map(artigo => (
                <Col key={artigo.id}>
                  <Link style={{
                    textDecoration: 'none',
                    color: 'black'
                  }} 
                  to={slugToUrl('artigos', artigo.Slug)} 
                  >
                    <div>
                      <LazyLoadImage 
                        src={ctx.api_url + artigo.Imagem.Imagem.url} 
                        alt={artigo.titulo + " Imagem"} 
                        width="200px" 
                        height="200px"
                        effect="blur"
                        style={{
                          padding: "5px",
                          borderRadius:"50%",
                          WebkitBoxShadow: "0px 4px 5px 0px rgba(0,0,0,0.38)",
                          MozBoxShadow: "0px 4px 5px 0px rgba(0,0,0,0.38)",
                          boxShadow: "0px 4px 5px 0px rgba(0,0,0,0.38)",
                        }}
                      />
                      <p className="blog-info-titulo">{artigo.Titulo}</p>
                      <ul className="blog-info-info">
                          <li>Autor: {artigo.Autor}</li> {" "}
                      </ul>
                    </div>
                  </Link>
                </Col>
            ))
          }   
        </Row>
        <hr />
        <Row className="justify-content-center">
          <Paginacao
            numPaginacaoActual={paginacaoActual} 
            numArtigosTotal={data.length} 
            artigosPorPagina={artigosPorPagina} 
            alternaPaginacao={alternaPaginacao} 
          />
        </Row>
      </Container>
  )
}