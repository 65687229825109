import React from 'react'
import {
    Container,
    Row,
    Col
} from 'react-bootstrap'

//Imgs
import {LazyLoadImage} from 'react-lazy-load-image-component'
import Logo from '../../imagens/CGSNEWLogo.png'

export default function NotFound404() {
    return (
        <Container>
            <Row>
                <Col style={{textAlign:"center", marginTop:"25px", padding: "50px"}}>
                    <LazyLoadImage src={Logo} alt="cgs logo" width="350" />
                    <h4 style={{margin: "12px"}} >A página solicitada não foi encontrada.</h4>
                    <a href="/">Home</a>
                </Col>
            </Row>
        </Container>
        
    )
}