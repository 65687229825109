import React, { useContext } from 'react'
import {
    Container,
    Row,
    Col
} from 'react-bootstrap'
//Css
import './cgs.css'
//Imgs
import {LazyLoadImage} from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css';
//React-Markdown & Renderers
import ReactMarkdown from 'react-markdown'
import {renderers} from '../../util/reactMarkdownRenderers'
//Contexto
import { CtxContext } from '../../contexto/ctx'

export default function CGS()  {
    const ctx = useContext(CtxContext)

    return (
        <Container fluid id="cgs">
            <Row>
                <Col>
                    <LazyLoadImage 
                        src={ctx.api_url+ctx.pageData.PerfilCGS.Imagem_perfil.formats.small.url}
                        width="250px"
                        effect="blur"
                        style={{
                            backgroundColor: 'white',
                            padding: '3px',
                            marginTop: "35px",
                            borderRadius: "50%",
                            WebkitBoxShadow: "0px 4px 5px 3px rgba(0,0,0,0.38)",
                            MozBoxShadow: "0px 4px 5px 3px rgba(0,0,0,0.38)",
                            boxShadow: "0px 4px 5px 3px rgba(0,0,0,0.38)",
                        }}
                    />
                </Col>
            
            </Row>
            <Row className="justify-content-center">
                <Col className="cgs-bio-text">
                    <ReactMarkdown renderers={renderers(ctx.api_url)} source={ctx.pageData.PerfilCGS.Texto} />
                </Col>
            </Row>
        </Container>
    )
}
