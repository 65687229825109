import React, {useState, useEffect} from 'react'
import {
    Navbar,
    Nav
} from 'react-bootstrap'
//css
import './navtop.css'
//Imgs
import {LazyLoadImage} from 'react-lazy-load-image-component'
import Logo from '../../imagens/CGSLogoNewIconSmall.png'
//Utils
import { debounce } from '../../util/utils'
//Componentes
/* import NavDropDownMenu from '../NavDropDownMenu/navDropDownMenu'
 */

export default function NavTop() {
    const [prevScrollPos, setPrevScrollPos] = useState(0)
    const [visible, setVisible] = useState(false)

    const navbarStyles = {
        padding: '0px',
        position: 'fixed',
        width: '100%',
        borderBottom: '1px solid #201a2355',
        zIndex: '100',
        transition: 'top 0.3s',
        opacity: '0.96',
        fontFamily: 'OpenSans',
        fontWeight: "bold"
       
    }

    const handleScroll = debounce(() => {
        const currentScrollPos = window.pageYOffset
        setVisible((prevScrollPos < currentScrollPos && prevScrollPos - currentScrollPos < 70) || currentScrollPos > 10)
        setPrevScrollPos(currentScrollPos)
    }, 100)

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
    },[prevScrollPos, visible, handleScroll]) 
    
    return (
        <Navbar bg="light" variant="light" expand="sm" style={{ ...navbarStyles, top: visible ? '0' : '-80px' }}>
            <Navbar.Brand href="/">
            <LazyLoadImage 
                width="45px" 
                src={Logo} 
                alt="cgs logo"
                style={{
                    marginLeft: "12px"
                }}
            />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" style={{textAlign: 'center'}}>
                <Nav className="mr-auto">
                <Nav.Link href="/">HOME</Nav.Link>
                <Nav.Link href="/sobre">SOBRE</Nav.Link>
                    
               {/*  <NavDropDownMenu /> */}

                <Nav.Link href="/servicos-categorias">SERVICOS</Nav.Link>
                <Nav.Link href="/blog">BLOG</Nav.Link>
                <Nav.Link href="/contactos">CONTACTOS</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}