import React, { useEffect, useContext, useState } from 'react'
import axios from 'axios'
import _ from 'lodash'
//css
import './rd.css'
//Imgs
import {LazyLoadImage} from 'react-lazy-load-image-component'
//Contexto
import { CtxContext } from '../../contexto/ctx'

export default function Rd() {
    const [data, setData] = useState({})
    const ctx = useContext(CtxContext)

    useEffect(() => {
        axios.get(`${ctx.api_url}/redes-sociais`)
        .then(response => {
          setData(response.data)
        })
    }, [ctx.api_url])

    return (
        <> {!_.isEmpty(data) &&
                <div className="rd-painel">
                    {data.Icon.map(rs=> (
                        <a 
                            key={rs.id}
                            href={rs.Url} 
                            target="_blank" 
                            rel="noreferrer" 
                            aria-label="link"
                        >
                            <LazyLoadImage src={ctx.api_url + rs.Imagem.formats.thumbnail.url} width="40px" style={{margin:"8px"}}/>
                        </a>
                    ))}
                </div>
            }
        </>
        
    )
}