import React, { useContext } from 'react'
import  {
    Container,
    Row,
    Col
} from 'react-bootstrap'
//Imgs
import {LazyLoadImage} from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css';
import Separador from '../../imagens/CGSLogoNewIconSmall.png'
//CSS
import './promoNovidades.css'
//React-Markdown & Renderers
import ReactMarkdown from 'react-markdown'
import {renderers} from '../../util/reactMarkdownRenderers'
//Contexto
import { CtxContext } from '../../contexto/ctx'
//Componentes
import Subscrever from '../Subscrever/subscrever'


export default function PromoNovidades() {
    const ctx = useContext(CtxContext)
    return (
        <Container className="promo-novidades" fluid>
            <Row>
                <Col md="12">
                    <h3 className="promo-novidades-titulo">PROMOÇÕES | NOVIDADES</h3>
                </Col>
            </Row>
            <Row className="promo-novidades-contentor justify-content-center">
                <Col md={6} className="promo-novidades-conteudo">
                    <ReactMarkdown renderers={renderers(ctx.api_url)} source={ctx.pageData.PromoNovidades.Promos} />  
                </Col>
                <Col md={12}>
                    <div className="promo-novidades-contentor-img">
                        <LazyLoadImage 
                            src={Separador}
                            width="100px"
                            effect="blur"
                            style={{
                                backgroundColor: 'white',
                                borderRadius: "50%",
                                WebkitBoxShadow: "0px 4px 5px 3px rgba(0,0,0,0.38)",
                                MozBoxShadow: "0px 4px 5px 3px rgba(0,0,0,0.38)",
                                boxShadow: "0px 4px 5px 3px rgba(0,0,0,0.38)",
                            }}
                        />
                    </div>
                    
                </Col>
                <Col md={6} className="promo-novidades-conteudo">
                    <ReactMarkdown renderers={renderers(ctx.api_url)} source={ctx.pageData.PromoNovidades.Novidades} />
                </Col>
            </Row>
            <Row>
                <Col md="12" style={{textAlign: "center"}}>
                    <Subscrever textoBotao="Subscreva a newsletter para receber notificações!"/>
                </Col>
            </Row>
        </Container>
    )
}