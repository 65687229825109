import React, { useContext, useState } from 'react'
import {
    Button,
    Modal,
    Form
} from 'react-bootstrap'
import axios from 'axios'
import {v4 as uuidv4} from 'uuid'
//Contexto
import {CtxContext} from '../../contexto/ctx'

export default function Subscrever(props) {
    const [email, setEmail] = useState("")
    const [show, setShow] = useState(false)
    const {api_url} = useContext(CtxContext)
    const [isLoading, setIsLoading] = useState(false)

    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)

    function handleChange(e) {
        e.preventDefault()
        setEmail(e.target.value)
    }

    function subscrever(e) {
        setIsLoading(true)
        e.preventDefault()
        axios.post(`${api_url}/subscritores`, {
            Email: email,
            Email_tag: uuidv4()
        }).then(res => {
            if(res.status === 200) alert('Subscrição efetuada com sucesso ✔')
            setIsLoading(false)
        }, () => {
            alert('Email introduzido ñ é válido')
            setIsLoading(false)
        })
    }

    return (
        <>
            <Button 
                onClick={handleShow}
                variant="info"
                        style={{
                            marginTop: "2px",
                            WebkitBoxShadow: "0px 4px 5px 0px rgba(0,0,0,0.38)",
                            MozBoxShadow: "0px 4px 5px 0px rgba(0,0,0,0.38)",
                            boxShadow: "0px 4px 5px 0px rgba(0,0,0,0.38)",
                        }}
            >
            <i className="fas fa-envelope-open-text"></i> {props.textoBotao}
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title><i className="fas fa-envelope-open-text"></i> Subscrever Newsletter</Modal.Title>
                </Modal.Header>
                {isLoading ? 
                <div className="loader"></div>
                :
                <Modal.Body>
                    <Form onSubmit={subscrever}>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Receba informação relacionada com serviços e actividades!</Form.Label>
                            <Form.Control 
                                type="email" 
                                placeholder="Endereço de email" 
                                onChange={handleChange}
                                required
                                minLength="6"
                                maxLength="75"
                            />
                            <Form.Text className="text-muted">
                            Pode sempre pedir para anular a sua subscrição utilizando o formulário de contacto ou através do endereço de email carla.gsantos@gmail.com.
                            </Form.Text>
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            Subscrever
                        </Button>
                    </Form>
                </Modal.Body>
                }
                <Modal.Footer>
                {/* Info adicional */}
                </Modal.Footer>
            </Modal>
        </>
    )
}
