import React, { useEffect, useState } from 'react'
import axios from 'axios'
import _ from 'lodash'


export const CtxContext = React.createContext()

export function CtxProvider(props) {
    const api_url = "https://api.carlagabrielsantos.com"
    const [data, setData] = useState({})

    useEffect(() => {
        axios.get(`${api_url}/pagina`)
        .then(response => {
            setData(response.data)
        })
    },[api_url])
    

    return (
        <> {!_.isEmpty(data) && 
            <CtxContext.Provider value={{api_url: api_url, pageData: data}}>
                    {props.children}
            </CtxContext.Provider>
        }  
        </>
        
    )
}