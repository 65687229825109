import React from 'react'
import {Pagination} from 'react-bootstrap'



export default function Paginacao({numPaginacaoActual, numArtigosTotal, artigosPorPagina, alternaPaginacao}) {

    //cria o index de paginação
    const indexPaginacao = []
    for (let i = 1; i <= Math.ceil(numArtigosTotal / artigosPorPagina); i++) {
        indexPaginacao.push(i);
    }
    
    //calcula o ultimo num da paginaçao
    let ultimoNumPaginacao = 1
    if(artigosPorPagina < numArtigosTotal) ultimoNumPaginacao = Math.ceil(numArtigosTotal / artigosPorPagina)

    return (
        <Pagination size="sm">
            <Pagination.First onClick={()=> alternaPaginacao(1)} />
            {numPaginacaoActual !== 1 && (<Pagination.Prev onClick={()=> alternaPaginacao(numPaginacaoActual - 1)} />)}
            {indexPaginacao.map(num => {
                    if(numPaginacaoActual === num) {
                        return(
                            <Pagination.Item key={num} active onClick={()=> alternaPaginacao(num)}>
                                {num}
                            </Pagination.Item>
                        )
                    } else {
                        return(
                            <Pagination.Item key={num} onClick={()=> alternaPaginacao(num)}>
                                {num}
                            </Pagination.Item>
                        )
                    }
                }                    
            )}
            {numPaginacaoActual !== ultimoNumPaginacao && (<Pagination.Next onClick={()=> alternaPaginacao(numPaginacaoActual + 1)}/>)}
            <Pagination.Last onClick={()=> alternaPaginacao(ultimoNumPaginacao)} />
        </Pagination>
    )
}