import React, { useContext, useEffect, useState } from "react"
import {
  Container,
  Row,
  Col
} from 'react-bootstrap'
import axios from 'axios'
import {Link} from 'react-router-dom'
//Imgs
import { LazyLoadImage } from "react-lazy-load-image-component"
import 'react-lazy-load-image-component/src/effects/blur.css'
//Utils
import {slugToUrl} from '../../util/utils'
//contexto
import {CtxContext} from '../../contexto/ctx'
//CSS 
import "./servicos.css"


export default function Servicos(props) {
  const ctx = useContext(CtxContext)
  const [servicosData, setServicosData] = useState([])
  const [categoriaData, setCategoriaData] = useState([])
  const {slug} = props.match.params

  useEffect(() => {
    axios.get(`${ctx.api_url}/servicos?categoria.Slug=${slug}`)
    .then(response => {
      setServicosData(response.data)
    })
    axios.get(`${ctx.api_url}/categorias?Slug=${slug}`)
    .then(response => {
      setCategoriaData(response.data)
    })
  }, [ctx.api_url, slug])

  return (
      <Container className="servico" >
        <Row>
          <Col>
          <h3>{categoriaData[0] && categoriaData[0].Nome}</h3>
          </Col>
        </Row>
        <Row style={{
          textAlign:"center",
          margin: "25px 0px"
          }}
        >
          {
            servicosData.map(servico => (
                <Col key={servico.id}>
                  <Link style={{
                    textDecoration: 'none',
                    color: 'black'
                  }} 
                  to={slugToUrl('servicos', servico.Slug)} 
                  >
                    <div>
                      <LazyLoadImage 
                        src={ctx.api_url + servico.ImagemMenu.url} 
                        alt={servico.Titulo + " Imagem"} 
                        width="200px" 
                        height="200px"
                        effect="blur"
                        style={{
                          padding: "5px",
                          borderRadius:"50%",
                          WebkitBoxShadow: "0px 4px 5px 0px rgba(0,0,0,0.38)",
                          MozBoxShadow: "0px 4px 5px 0px rgba(0,0,0,0.38)",
                          boxShadow: "0px 4px 5px 0px rgba(0,0,0,0.38)",
                        }}
                      />
                      <p className="servicos-info-titulo">{servico.Titulo}</p>
                      
                    </div>
                  </Link>
                </Col>
            ))
          }   
        </Row>
      </Container>
  )
}