import React, { useContext } from 'react'
import {
    Container,
    Row,
    Col,
    Button
} from 'react-bootstrap'
//Css
import './teaser.css'
//Imgs
import TeaserBG from '../../imagens/emotionsGrad.jpg'
//React-Markdown & Renderers
import ReactMarkdown from 'react-markdown'
import {renderers} from '../../util/reactMarkdownRenderers'
//Contexto
import { CtxContext } from '../../contexto/ctx'

export default function Teaser() {
    const ctx = useContext(CtxContext)
    
    return (
        <Container fluid style={{
            height: "auto",
            backgroundColor: '#ccc',
            backgroundImage: `url(${TeaserBG})`,
            backgroundSize:'cover',
            backgroundRepeat: 'no-repeat',
            padding: "30px",
            fontFamily: "Nunito"
        }}>
            <Row className="teaser-conteudo" >
                <Col md={8}>
                    <div>
                        <ReactMarkdown renderers={renderers(ctx.api_url)} source={ctx.pageData.Teaser2.Texto} />
                    </div>
                </Col>
                <Col md={4} className="teaser-butao">
                        
                            <Button 
                                variant="info"
                                href="/contactos"
                                style={{
                                    WebkitBoxShadow: "0px 4px 5px 0px rgba(0,0,0,0.38)",
                                    MozBoxShadow: "0px 4px 5px 0px rgba(0,0,0,0.38)",
                                    boxShadow: "0px 4px 5px 0px rgba(0,0,0,0.38)",
                                    marginTop: "12px"
                                }}
                            >
                                <i className="fas fa-check-square"></i> {ctx.pageData.Teaser2.Botao}
                            </Button>
                       
                    
                    
                </Col>
            </Row>
        </Container>
    )
}