import React from 'react'
import './testemunho.css'
//imgs
import Logo from '../../imagens/CGSLogoNewIcon.jpg'

export default function Testemunho(prop) {
    return (
        <div className="testemunho-container">
            <div className="testemunho-container-box">
                <img src={Logo} className="testemunho-container-box-logo" alt="Website logo"/>
                <h2> Testemunho: </h2>
                <p className="testemunho-container-msg">{prop.msg}</p>
            </div>
        </div>
        
    )
}

