import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import {
    Container,
    Row,
    Col
} from 'react-bootstrap'
import _ from 'lodash'
import ReactMarkdown from 'react-markdown'
//Contexto
import {CtxContext} from '../../contexto/ctx'
//Css
import "./servicoTemplate.css"
//React-Markdown Renderers
import {renderers} from '../../util/reactMarkdownRenderers'
//Componentes
import NotFound404 from '../../Paginas/404/404'


export default function ServicoTemplate(props) {
    const ctx = useContext(CtxContext)
    const [servico, setServico] = useState({})
    const {slug} = props.match.params
    //const faceShareUrl = "https://www.facebook.com/plugins/share_button.php?href="+ encodeURIComponent(window.location.href) + "layout=box_count&size=small&width=82&height=40&appId"
    
    useEffect(()=> {
        axios.get(`${ctx.api_url}/servicos?Slug=${slug}`)
        .then(response => {
           setServico(response.data[0])  
        })
    }, [slug, ctx.api_url])
    
    if(_.isEmpty(servico)) {
        return (<NotFound404/>)
    } else {
        return (
            <>
                {!_.isEmpty(servico) && //Para ter a certeza que o servico está presente.
                <Container className="servico">
                    <Row>
                        <Col>
                            <h1 className="servico-titulo">{servico.Titulo}</h1>
                            {servico.Subtitulo && <h3 className="servico-info">{servico.Subtitulo}</h3>} 
                            {/* <iframe 
                                title="faceShareButton"                        
                                src={faceShareUrl} 
                                width="82" 
                                height="40" 
                                style={{border:"none" ,overflow:"hidden"}} 
                                scrolling="no" 
                                frameBorder="0" 
                                allowFullScreen={true}
                                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                            ></iframe> */}
                        </Col>
                    </Row>
                    <Row >
                        <Col>
                            {servico.Pequena_descricao && <h4 className="servico-peq-descricao">{servico.Pequena_descricao}</h4> } 
                            <div>
                                <ReactMarkdown renderers={renderers(ctx.api_url)} source={servico.Texto} />
                            </div>
                        </Col>
                    </Row>
                </Container>
                }
            </>
            
        )
    }
}