import {Route} from 'react-router-dom'

/**Impede os eventos de dispararem */
export function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
}

/**cria um caminho para a api*/
export const slugToUrl = (path, slug) => `${path}/${slug}` 

/**Para implementar layouts para as paginas*/
export const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
	<Route {...rest} render={props => (
	  <Layout>
		<Component {...props} />
	  </Layout>
	)} />
  )