import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import {
    Container,
    Row,
    Col
} from 'react-bootstrap'
import _ from 'lodash'
//Contexto
import {CtxContext} from '../../contexto/ctx'
//React-Markdown & Renderers
import ReactMarkdown from 'react-markdown'
import {renderers} from '../../util/reactMarkdownRenderers'
//Imgs
import {LazyLoadImage} from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
//CSS
import './artigoTemplate.css'
//Componentes
import NotFound404 from '../../Paginas/404/404'


export default function ArtigoTemplate(props) {
    const ctx = useContext(CtxContext)
    const [artigo, setArtigo] = useState({})
    const {slug} = props.match.params
    const faceShareUrl = "https://www.facebook.com/plugins/share_button.php?href="+ window.location.href

    useEffect(()=> {
        axios.get(`${ctx.api_url}/artigos?Slug=${slug}`)
        .then(response => {
            setArtigo(response.data[0])    
        })
    }, [slug, ctx.api_url])
    
    if(_.isEmpty(artigo)) {
        return (<NotFound404/>)
    } else {
        return (
            <>{!_.isEmpty(artigo) && //Para ter a certeza que o artigo está presente.
                <Container fluid className="artigo">
                    <Row>
                        <Col>
                            <h2 className="artigo-titulo">{artigo.Titulo}</h2>
                            <ul className="artigo-info">
                                <li>Autor: {artigo.Autor}</li> {" - "}
                                {/* <li>Data: {artigo.created_at.slice(0,10)}</li> */}
                            </ul>
                            <hr />
                             <iframe 
                                title="faceShareButton"                        
                                src={faceShareUrl} 
                                width="82" 
                                height="40" 
                                style={{border:"none" ,overflow:"hidden"}} 
                                scrolling="no" 
                                frameBorder="0" 
                                allowFullScreen={true}
                                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                            ></iframe> 

                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <LazyLoadImage 
                                className="artigo-imagem"
                                src={ctx.api_url + artigo.Imagem.Imagem.url} 
                                alt={artigo.Imagem.Titulo + " Imagem"} 
                                effect="blur"
                                style={{
                                    borderRadius:"12px",
                                    WebkitBoxShadow: "4px 4px 5px 0px rgba(0,0,0,0.38)",
                                    MozBoxShadow: "4px 4px 5px 0px rgba(0,0,0,0.38)",
                                    boxShadow: "4px 4px 5px 0px rgba(0,0,0,0.38)",
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {artigo.Subtitulo && <h3 className="artigo-info-subtitulo">{artigo.Subtitulo}</h3>} 
                        </Col>
                    </Row>
                    <Row>
                        <Col >
                            
                            {artigo.Pequena_descricao && <h2 className="artigo-peq-descricao">{artigo.Pequena_descricao}</h2> } 
                            <div>
                                <ReactMarkdown renderers={renderers(ctx.api_url)} source={artigo.Texto} />
                            </div>    
                        </Col>
                    </Row>
                </Container>
                }
            </>
            
        )
    }
}