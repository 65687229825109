import React, { useContext } from 'react'
import './hero.css'
import { 
    Container,
    Jumbotron,
    Row,
    Col
} from 'react-bootstrap'
import {Link} from 'react-router-dom'
//Imgs
import {LazyLoadImage} from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css';
//Contexto
import { CtxContext } from '../../contexto/ctx'
//Componentes
/* import DropDownMenu from '../DropDownMenu/dropDownMenu' */

export default function Hero() {
    const ctx = useContext(CtxContext)
    
    return(
    <Jumbotron fluid id="hero-jumbotron"
        style={{
            backgroundImage: `url(${ctx.api_url+ctx.pageData.Cabecalho.Imagem_cabecalho.url})`,
            backgroundSize:'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
        }} 
    >
        <Container fluid >
            <Row className="justify-content-center">
                <LazyLoadImage 
                    effect="blur" 
                    width="450px"
                    src={ctx.api_url+ctx.pageData.Cabecalho.Logo.url} 
                    alt={ctx.pageData.Cabecalho.Logo.alternativeText || "Logo cgs"}
                    style={{marginBottom: "12px"}}
                />
            </Row>
            <Row>
                <Col style={{textAlign:"center"}}>
                    <ul id="hero-ul">
                        <li className="hero-li"><Link to='/'>HOME</Link> </li>
                        <li className="hero-li"><Link to='/sobre'>SOBRE</Link></li>
                        {/* <DropDownMenu tema="hero"/> */}
                        <li className="hero-li"><Link to='/servicos-categorias'>SERVICOS</Link></li>
                        <li className="hero-li"><Link to='/blog'>BLOG</Link></li>
                        <li className="hero-li"><Link to='/contactos'>CONTACTOS</Link></li>
                    </ul>
                </Col>
            </Row>
            <Row className="hero-info text-center">
                <Col md={12}>
                    <h1 className="hero-info-item">{ctx.pageData.Cabecalho.Titulo}</h1>
                </Col>
            
                <Col md={12} >
                    <h3 className="hero-info-item">{ctx.pageData.Cabecalho.Subtitulo}</h3>
                </Col>
            </Row>
        </Container>
    </Jumbotron>
)}
