import React from "react"
//Cookie consent
import CookieConsent from 'react-cookie-consent'
//Componentes
import DefaultLayout from '../../Layouts/defaultLayout'
import Hero from '../../Componentes/Hero/hero'
import Info from '../../Componentes/Info/info'
import CGS from '../../Componentes/CGS/cgs'
import Teaser from '../../Componentes/Teaser/teaser'
import Testemunhos from '../../Componentes/Testemunhos/testemunhos'
import Rd from '../../Componentes/RedesSociais/rd'
import PromoNovidades from "../../Componentes/PromoNovidades/promoNovidades"

export default function Home() {
    return (
        <DefaultLayout>
            <Hero />
            <Info />
            <CGS />
            <Teaser />
            <PromoNovidades />
            <Testemunhos />
            <Rd />
            <CookieConsent
                location="bottom"
                style={{ background: "#2B373B" }}
                buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                buttonText="Aceito ✔"
                expires={150}
                cookieName="UserCookie"
            >
                Este Website usa cookies para melhorar a sua experiencia.
                Ao continuar a navegar no site está a concordar com as nossas {" "}
                <a href="/politicas-de-privacidade" target="_blank">politicas de privacidade</a>.
            </CookieConsent>
        </DefaultLayout>
    )
}