import React from 'react'
//Componentes
import Footer from '../Componentes/Footer/footer'
import NavTop from '../Componentes/NavTop/navtop'
import PagesHeader from '../Componentes/PagesHeader/pagesHeader'

export default function PagesLayout({children}) {

    return (
        <>
            <NavTop />
            <PagesHeader />
            {children}
            <Footer />
        </>
    )
}