import React, { useContext, useEffect, useState } from 'react'
import {
    Container,
    Row,
    Col
} from 'react-bootstrap'
import axios from 'axios'
import _ from 'lodash'
//css
import './sobre.css'
//Contexto
import {CtxContext} from '../../contexto/ctx'
//React-Markdown & Renderers
import ReactMarkdown from 'react-markdown'
import {renderers} from '../../util/reactMarkdownRenderers'


export default function Sobre() {
  const ctx = useContext(CtxContext)
  const [data, setData] = useState({})

  useEffect(() => {
    axios.get(`${ctx.api_url}/sobre`)
    .then(response => {
      setData(response.data)
    })
  }, [ctx.api_url])


  return (
    <>
    {!_.isEmpty(data) && 
      <Container fluid className="sobre">
        <Row style={{
          backgroundImage: `url(${ctx.api_url+data.Imagem.url})`,
          backgroundSize:'cover',
          backgroundPosition: "center",
          backgroundRepeat: 'no-repeat',
          color: "#e4dfda",
          height: "300px"
        }}>
          <Col>
              <div className="sobre-titulo" >
                <h1>{data.Titulo}</h1>
                <h3>{data.Subtitulo}</h3>
              </div>    
          </Col>
        </Row>
        <Row className="sobre-conteudo">
          <Col style={{
            textAlign: "center"
          }}>
            <ReactMarkdown renderers={renderers(ctx.api_url)} source={data.Texto} />  
          </Col>
        </Row>
      </Container>
    }
    </>
  )
}