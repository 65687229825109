import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";
//Imgs
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
//Utils
import { slugToUrl } from "../../util/utils";
//contexto
import { CtxContext } from "../../contexto/ctx";
//CSS
import "./servicosCategorias.css";

export default function ServicosCategorias() {
  const ctx = useContext(CtxContext);
  const [data, setData] = useState([]);

  useEffect(() => {
    axios.get(`${ctx.api_url}/categorias`).then((response) => {
      setData(response.data);
    });
  }, [ctx.api_url]);

  return (
    <Container className="servicos-categoria">
      <Row className="servicos-categorias-row">
        <Col>
          <h3 className="servicos-categorias-titulo">Escolha uma categoria de serviços</h3>
        </Col>
      </Row>
      <Row className="servicos-categorias-row">
        {data.map((categoria) => (
          <Col key={categoria.id}>
            <Link
              style={{
                textDecoration: "none",
                color: "black",
              }}
              to={slugToUrl("servicos-categorias", categoria.Slug)}
            >
              <LazyLoadImage
                src={ctx.api_url + categoria.Imagem.url}
                alt={categoria.Name + " Imagem"}
                width="200px"
                height="200px"
                effect="blur"
                style={{
                  padding: "5px",
                  borderRadius: "50%",
                  WebkitBoxShadow: "0px 4px 5px 0px rgba(0,0,0,0.38)",
                  MozBoxShadow: "0px 4px 5px 0px rgba(0,0,0,0.38)",
                  boxShadow: "0px 4px 5px 0px rgba(0,0,0,0.38)",
                }}
              />
            <div>
              <h6 className="servicos-categorias-servicos-nome">{categoria.Nome}</h6>
            </div>
            </Link>
            {/* <p className="servicos-categoria-descricao">info</p> */}
          </Col>
        ))}
      </Row>
    </Container>
  );
}
